import * as React from "react"
import { graphql, Link } from 'gatsby'

const IndexPage = ({data}) => {
  return (
    <main>
      <h1>Boliglån.online</h1>
      <p>Sammenlign boliglån online i denne oversikten over banker som tilbyr boliglån i det norske markedet</p>
      <h2>Banker som tilbyr private boliglån:</h2>
      <p>Følgende liste viser de største bankene som tilbyr boliglån til privatkunder:</p>
      <ul>
          {data.allSanityBank.edges.map(({node},i) => {
              return(
                  <li key={i}><Link to={'/'+node.slug.current}>{node.title}</Link></li>
              )
          })}
      </ul>
    </main>
  )
}

export default IndexPage


export const query = graphql`
query BankQuery {
      allSanityBank(filter: { slug: { current: { ne: null } } }, sort: {order: ASC, fields: slug___current}) {
        edges {
          node{
            slug{
                current
            }
            title
            intro
            url
          }
        }
      }
    }
`